.App {
  text-align: center;
  background-color: #282c34;
  padding-top: 3vh;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.PayloadOptions {
  display: inline-block;
  margin: 1vh;
  width: fit-content;
  height: fit-content;
}